import ResultCard from "./ResultCard";
import "./Results.css";
import Spinner from "react-spinkit";
import {
  IAvailabilityFlexResult,
  Accommodationtypes,
  IAccommodationtype,
} from "@bookingflow/types";
import { DateTime } from "luxon";
import {Functions} from "firebase/functions"
import React, { useEffect, useState } from "react";
import { PaymentIntent } from "@stripe/stripe-js";

import { Container, Typography } from '@mui/material';

export interface IResultProps {
  error: string;
  availabilityResults: IAvailabilityFlexResult;
  accommodations: Accommodationtypes;
  accommodation: string;
  arrive: Date;
  depart: Date;
  adults: number | undefined;
  children: number | undefined;
  infants: number | undefined;
  spinner: boolean;
  siteId: string;
  images: string[];
  flex: number
  startTimer: () => void;
  func: Functions;
  setSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentIntent: React.Dispatch<
    React.SetStateAction<PaymentIntent | string | null>
  >;
  checkAvailability: () => void;
}

export interface IResultState {}

const Results = (props: IResultProps) => {
  const [results, setResults] = useState<JSX.Element[]>([]);
  const {
    availabilityResults,
    accommodation,
    accommodations,
    arrive,
    depart,
    adults,
    children,
    infants,
    checkAvailability,
  } = props;
  useEffect(() => {
    // on refresh we have to run checkAvailability first
    //if any search params are null then we can't do anything
    const requiredSearchParams = [
      accommodation,
      arrive,
      depart,
      adults,
      children,
      infants,
    ];
    if (
      requiredSearchParams.every((param) => param !== null) &&
      !props.availabilityResults.id
      ) {
        //If the search params are all available and availability results don't already exist then trigger checkAvailability
        checkAvailability();
      } else if (accommodations.length > 0) {
      let items: JSX.Element[];
      if (!props.availabilityResults.availability.isAvailable) {
        items = [<p>{props.error}</p>];
      }
      const result = props.availabilityResults;
      const accommodation = accommodations.find(
        (accommodation: IAccommodationtype) => accommodation.id === result.id
      );
      if (!accommodation) {
        throw new Error(`cannot find accommodation with id: ${result.id}`);
      }
      items = [
        <ResultCard
          key={0}
          id={result.id}
          accommodation={accommodation}
          nights={
            DateTime.fromJSDate(props.depart).diff(
              DateTime.fromJSDate(props.arrive),
              "days"
            ).days
          }
          images={props.images}
          adults={props.adults as number}
          availabilityResult={result}
          children={props.children as number}
          infants={props.infants as number}
          flex={props.flex}
          siteId={props.siteId}
          startTimer={props.startTimer}
          setPaymentIntent={props.setPaymentIntent}
        />,
      ];
      setResults(items);
    }
    // eslint-disable-next-line
  }, [
    accommodations,
    accommodation,
    arrive,
    depart,
    adults,
    children,
    infants,
    availabilityResults,
  ]);

  return (
    <Container id="results">
      <Typography variant="h4" component="h2">Search Results</Typography>
      {props.spinner && (
        <Spinner name="pacman" color="grey" className="loader" />
      )}
      {!props.spinner && results}
    </Container>
  );
};

export default Results;
