import FourOFour from "./FourOFour";
import BrandHeader from "./BrandHeader";
import TimeoutSnackbar from "./TimeoutSnackbar";
import React from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline } from '@mui/material';
interface BrandingProps {
  siteId: string;
  timeoutMinutes: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const Branding = (props: BrandingProps) => {
  return (
    <div className="search">
      {props.siteId === null && <FourOFour />}
      {props.siteId && (
        <div id="site-container">
          <BrandHeader siteId={props.siteId} />
          <section id="portal">
            <TimeoutSnackbar
              open={props.open}
              setOpen={props.setOpen}
              timeoutMinutes={props.timeoutMinutes}
            />
            <CssBaseline />
            <Outlet />
          </section>
        </div>
      )}
    </div>
  );
};

export default Branding;
