import "./GlanCeirwHeader.css";
const GlanCeirwHeader = () => (
  <div id="glanceirw-header">
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Droid+Sans"
    />
    <header>
      <div className="container">
        <div className="row">
          <img
            id="logo"
            src="https://glanceirw.com/img/finalLogo.png"
            alt="Glan Ceirw Caravan Park Logo"
            className="col-md-2 col-6 img-fluid"
          />
          <nav id="headernav" role="navigation" className="col-md-8">
            <div className="container-fluid">
              <ul className="nav col-12">
                <li className="col-2 offset-1">
                  <a href="https://glanceirw.com/" className="active">
                    Home
                  </a>
                </li>
                <li className="col-2">
                  <a href="https://glanceirw.com/about/">About</a>
                </li>
                <li className="col-2">
                  <a href="https://glanceirw.com/gallery/">Gallery</a>
                </li>
                <li className="col-2">
                  <a href="https://glanceirw.com/tariffs/">Tariffs</a>
                </li>
                <li className="col-2">
                  <a href="https://glanceirw.com/contact/">Contact</a>
                </li>
              </ul>
            </div>
          </nav>
          <div
            id="nav-toggle"
            className="col-md-2 col-6 row justify-content-end"
          >
            <a href="#navInner" className="col-4">
              <img
                src="https://glanceirw.com/img/icons/icon-menu.svg"
                alt="Menu"
              />
            </a>
          </div>
          <div
            id="book-container"
            className="col-md-2 col-12 justify-content-end align-items-center row"
          >
            <a
              id="book"
              href="https://portal.bookingflow.app/book?site_id=RAdjLcHEI68xqbPSnJvE"
              className="btn"
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
    </header>
  </div>
);
export default GlanCeirwHeader;
