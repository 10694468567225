import "./BagnolHeader.css";
const BagnolHeader = () => (
  <div id="bagnol">
    <div className="header">
      <header className="header-contain">
        <div className="header-left">
          <span>
            <img
              src="./static/images/bagnolLogo.jpg"
              alt="Bagnol &amp; Tyn Towyn Camping and Caravan Parks"
              title="Bagnol &amp; Tyn Towyn Camping and Caravan Parks"
            />
          </span>
        </div>
        <div className="header-right">
          <div className="header-right-tab">
            <div className="header-tel">
              <span>01407 860 223</span>
            </div>
          </div>
          <nav id="menu" className="menu">
            <ul>
              <li>
                <a href="index.aspx">Home</a>
              </li>
              <li>
                <a href="anglesey.aspx">Local Area</a>
              </li>
              <li>
                <a href="camping.aspx?cid=138&amp;pid=2089&amp;utid=338">
                  Touring
                </a>
              </li>
              <li>
                <a href="thepark.aspx">The Park</a>
              </li>
              <li>
                <a href="news.aspx">News</a>
              </li>
              <li>
                <a href="caravan_sales.aspx">Caravan Sales</a>
              </li>
              <li>
                <a href="gallery.aspx">Gallery</a>
              </li>
              <li>
                <a href="contact.aspx">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  </div>
);
export default BagnolHeader;
