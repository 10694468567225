import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {getFunctions, httpsCallable} from 'firebase/functions'
import {getApp} from 'firebase/app'
import { AvailabilityResults, IAccommodationtype, IAvailabilityFlexResult } from "@bookingflow/types";
import { DateTime, DateTimeFormatOptions } from "luxon";
import "./ResultCard.css";
import { PaymentIntent } from "@stripe/stripe-js";
import { convertToUTCDate, getNights } from "@bookingflow/utils";

import {
  Typography,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  Box,
  TableHead,
  TableRow,
  Grid,
  Card,
  Collapse,
  CardContent,
  Divider,
} from '@mui/material';
import { getAnalytics, logEvent } from "firebase/analytics";

export interface IResultCardProps {
  accommodation: IAccommodationtype;
  key: number;
  id: string;
  availabilityResult: IAvailabilityFlexResult; 
  nights: number;
  adults: number;
  children: number;
  infants: number;
  flex: number;
  siteId: string;
  images: string[];
  startTimer: () => void;
  setPaymentIntent: React.Dispatch<
    React.SetStateAction<PaymentIntent | string | null>
  >;
}
export interface IPitchFeatures {
  included: number;
  max: number;
}
export interface IPitchDetails {
  dimensions: string;
  area: string;
  water: boolean;
  tvHookup: boolean;
  chemicalDisposal: boolean;
  carParking: IPitchFeatures;
  awning: IPitchFeatures;
  dogs: IPitchFeatures;
}

//TODO: Prevent update unless availability is refreshed
//TODO: if priceArray < this.props.nights then prompt user
const ResultCard = (props: IResultCardProps) => {
  const location = useLocation()
  const [tab, setTab] = useState(0);
  const [isBooked, setIsBooked] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [showAlternative, setShowAlternative] = useState(false)
  const app = getApp()
  const func = getFunctions(app, 'europe-west2')
  if (!props.availabilityResult.recommended){
    const errorLogger = httpsCallable(func, "error")
    
    errorLogger({error: JSON.stringify(props.availabilityResult)})
    throw new Error(`System Error: Available but with no results set for ${location.pathname}`)
  }
  const [cost, setCost] = useState(props.availabilityResult.recommended.cost)
  const [arrive, setArrive] = useState(new Date(props.availabilityResult.recommended.arriveTime))
  const [depart, setDepart] = useState(new Date(props.availabilityResult.recommended.departTime))
  const accommodation = props.accommodation;
  const name =
    accommodation !== undefined ? accommodation.accommodation : undefined;

  const navigate = useNavigate();
  function handleShowAlternative() {
    setShowAlternative(!showAlternative)
  }
  async function handleBooking() {
    //the button should be disabled if there is no cost but just in case
    if (!cost || !cost?.totalCost) {
      return;
    }
    const totalCost: number = cost.totalCost;
    setSpinner(true);
    const utcArrive = convertToUTCDate(arrive).getTime()
    const utcDepart = convertToUTCDate(depart).getTime()
    const nights = getNights(new Date(utcArrive), new Date(utcDepart))
    const bookingData = {
      accommodationcostamount: totalCost,
      arrive: utcArrive,
      depart: utcDepart,
      created: DateTime.utc().toISO(),
      adults: props.adults,
      infants: props.infants,
      children: props.children,
      accommodationid: props.id,
      accommodation: props.accommodation.accommodation,
      costDetail: cost,
      nights: nights,
      status: "hold",
      cancelled: false,
      remainderamount: totalCost,
      siteId: props.siteId,
    };
    const submitBooking = httpsCallable(func, "portalSubmitBooking");
    const bookingRes = await submitBooking(bookingData);
    const docRef = bookingRes.data;
    const submitPaymentIntent = httpsCallable(func,
      "portalSubmitPaymentIntent"
    );
    const paymentIntentRes = await submitPaymentIntent({
      amount: totalCost * 100,
      docRef: docRef,
      siteId: props.siteId,
    }); //send amount in base currency - * 100 will be fine for USD EUR GBP
    const paymentIntent = paymentIntentRes.data as PaymentIntent
    props.setPaymentIntent(paymentIntent);
    setSpinner(false);
    const analytics = getAnalytics()
    logEvent(analytics, "begin_checkout")

    props.startTimer();
    navigate("/book/pay?site_id=" + props.siteId + "&id=" + docRef);
    setIsBooked(true);
  };

  function handleTabChange(event: React.ChangeEvent<{}>, value: number) {
    setTab(value);
  };
  const costBreakdown = (
    <div>
      <table className="tab-content">
        {!cost && (
          <tbody>
            <tr>
              <td>
                We are having trouble returning costs for your search criteria
                please try again later
              </td>
            </tr>
          </tbody>
        )}
        {cost && (
          <tbody>
            <tr>
              <td>Pitch Price</td>
              <td>£{cost.accommodationCost.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Additional persons: {props.adults} adults</td>
              <td>£{cost.adultCost.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Additional persons: {props.children} children</td>
              <td>£{cost.childCost.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Additional persons: {props.infants} infants</td>
              <td>£{cost.infantCost.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Total Stay ({props.nights.toFixed(0)} nights)</td>
              <td>£{cost.totalCost.toFixed(2)}</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
  const details = (
    <div className="tab-content details">
      <div>Dimensions:</div>

      <div>{`Max ${props.accommodation.dimensionsWidth}m width x ${props.accommodation.dimensionsLength}m length`}</div>
      <div>Area:</div>
      <div>{`Calculated area: ${props.accommodation.area}m²`}</div>
      <strong>Services</strong>
      <div>Water:</div>
      <div>{props.accommodation.water ? "Included" : "Not Included"}</div>
      <div>Electric:</div>
      <div>{"Available: £4.50"}</div>
      <div>Chemical Disposal:</div>
      <div>{props.accommodation.chemical ? "Available" : "Not Available"}</div>
      <strong>Features</strong>
      <div>Car Parking:</div>
      <div>
        {props.accommodation.parking ? "included, 1 max" : "Not Available"}
      </div>
      <div>Bring an Awning:</div>
      <div>
        {props.accommodation.awnings ? "included, 1 max" : "Not Allowed"}
      </div>
      <div>Dogs Allowed:</div>
      <div>{props.accommodation.dogs ? " included, 1 max" : "Not Allowed"}</div>
    </div>
  );
  function getCellIndex(i: number, j: number, numRows: number){
    return j * numRows + i
  }
  function getCell(results: AvailabilityResults, i: number, j: number, numRows: number ){
    const index = getCellIndex(i, j, numRows)
    return results[index]
  }
  function handleSetResult(i: number, j: number, numRows: number) {
    return (function(event: React.MouseEvent<HTMLElement, MouseEvent>){
      const results = props.availabilityResult.results
      if (!results){
        return null
      }
      const result = getCell(results, i, j, numRows)
      
      setCost(result.cost)
      setArrive(new Date(result.arriveTime))
      setDepart(new Date(result.departTime))
    })
  }
  function getTableContent() {
    if (props.flex === 0) {
      return []
    }
    const results = props.availabilityResult.results
    if (!results){
      return [];
    }
    const columns = [""]
    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' } as DateTimeFormatOptions;
    // The resultset is sorted by column then by row so get unique columns 
    // by skipping over the rows
    const numRows = props.nights === 1 ? 2 : 3
    for (let i = 0; i < results.length; i=i+numRows) {
      const date = new Date(results[i].arriveTime).toLocaleDateString("en-GB", dateOptions)
      columns.push(date);
    }
    //const rows: string[][] = [[]];
    //while(results.length) rows.push(results.splice(0, props.flex));
    let rows: string[][] = [];
    for (let i=0; i<numRows; i++){
      // first row is
      const rowLabelNumeric = props.nights === 1 ? props.nights + i : props.nights - 1 + i
      const rowLabelString = rowLabelNumeric === 1 ? `${rowLabelNumeric} Night` : `${rowLabelNumeric} Nights`

      let row: string[] = [rowLabelString];
      for (let j=0; j<columns.length - 1; j++){
        const result = getCell(results, i, j, numRows)
        //const result = results[getCell]
        if (result.isAvailable && result.cost) {
          const cost = result.cost.totalCost.toFixed(2)
          const formattedCost = `£${cost}`
          row.push(formattedCost)
        } else {
          row.push("Not Available")
        }
      }
      rows.push(row)
    }
    return[
      <React.Fragment>
        {columns.map((column, j) => (
          <TableCell align="center" key={`col${j}`}>{column}</TableCell>
        ))}
      </React.Fragment>,
      <React.Fragment>
          {
            rows.map((row, i) => (
              <TableRow key={`row${i}`}>
                {
                  row.map((cell, j) => {
                    if (j === 0) {
                      return <TableCell align="center" key={`cell${i}${j}`}>{cell}</TableCell>
                    }
                    if (cell === "Not Available"){
                      return <TableCell align="center" key={`cell${i}${j}`}><Button disabled={true}>{cell}</Button></TableCell>
                    } 
                    return <TableCell align="center" key={`cell${i}${j}`}><Button onClick={handleSetResult(i, j - 1, numRows)} >{cell}</Button></TableCell>
                  })
                }
              </TableRow>
            ))
          }
      </React.Fragment>
    ]
  }
  const [columns, rows] = getTableContent()
  return (
    <div key={0} className="results-div">
      <Divider />
      <Card className="results-card">
        <CardContent component={CardContent} sx={{ overflow: 'auto' }}>
          <Grid container spacing={3}>
            <Grid spacing={1} container item sm={6} xs={12} sx={{ overflow: 'hidden' }} className="results-image">
              <Grid item xs={12} className="hero-container">
                <img
                  className="hero-image img-fluid "
                  src={props.accommodation.image1}
                  alt=""
                />
              </Grid>
              <Grid item container xs={12} className="additional-container">
                <Grid item xs={6} className="additional-container-mask">
                  <img
                    className="additional-image"
                    src={props.accommodation.image2}
                    alt=""
                  />
                </Grid>
                <Grid item xs={6} className="additional-container-mask">
                  <img
                    className="additional-image "
                    src={props.accommodation.image3}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item sm={6} xs={12} className="results-text">
              <Grid item xs={12}>
                <Typography variant="body1" component="h3" className="results-title">{name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" >
                  <em>Check In:</em>{" "}
                  {DateTime.fromJSDate(arrive).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" >
                  <em>Check Out:</em>{" "}
                  {DateTime.fromJSDate(depart).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" >
                  <em>Party:</em> {props.adults} adults, {props.children} children and{" "}
                  {props.infants} infants{" "}
                </Typography>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab label="Cost Breakdown" />
                  <Tab label="Details" />
                  {/*<Tab label="Calendar" />*/}
                </Tabs>
                {tab === 0 && <div>{costBreakdown}</div>}
                {tab === 1 && <div>{details}</div>}
              </Grid>
              <Grid item xs={12}>
                {props.flex > 0 &&
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleShowAlternative}
                  >
                    {!showAlternative && "Show Alternative Dates"}
                    {showAlternative && "Hide Alternative Dates"}
                  </Button>
                }
                <LoadingButton
                  disabled={isBooked || cost === null}
                  color="primary"
                  variant="contained"
                  onClick={handleBooking}
                  loading={spinner}
                >
                  Book
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid spacing={1} container item xs={12} justifyContent="flex-end">
            <Box component={Collapse} orientation="vertical" in={showAlternative} sx={{ overflow: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows}
                    </TableBody>
                  </Table>
                </Box>
            </Grid>
          </Grid>
        </CardContent>
     
      </Card>
      
    </div>
  );
};

export default ResultCard;
