import React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Snackbar as SnackBar } from '@mui/material';

export interface TimeoutSnackBarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  timeoutMinutes: number;
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TimeoutSnackBar(props: TimeoutSnackBarProps) {
  const handleClose = (event: React.SyntheticEvent | Event, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    props.setOpen(false);
  };
  return (
    <div>
      <SnackBar
        open={props.open}
        autoHideDuration={20000}
        onClose={handleClose}
      >
        <Alert severity="warning">
          You have {props.timeoutMinutes} minutes to complete your purchase!
        </Alert>
      </SnackBar>
    </div>
  );
}
export default TimeoutSnackBar;
