import React from "react";
import BagnolHeader from "./BagnolHeader";
import "./BrandHeader.css";
import GlanCeirwHeader from "./GlanCeirwHeader";

export interface BrandHeaderProps {
  siteId: string;
}

const BrandHeader = (props: BrandHeaderProps) => {
  return (
    <div id="">
      <header>
        {props.siteId === "bagnol" && <BagnolHeader />}
        {props.siteId === "RAdjLcHEI68xqbPSnJvE" && <GlanCeirwHeader />}
      </header>
    </div>
  );
};
export default BrandHeader;
