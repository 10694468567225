import React from "react";
import "./Info.css";

import { Card, Typography, CardContent } from '@mui/material';

const Info = () => {
  return (
    <div>
      <Card id="info">
        <CardContent>
          <Typography component="h2" variant="h4">
            Staying with Glan Ceirw Caravan Park
          </Typography>
          <p>
            Receive a warm Welsh welcome at Glan Ceirw. A family owned and run
            site, the Parry Family have created a ‘hidden gem’ for you to enjoy
            with facilities, grounds and pitches maintained to a very high
            standard throughout. Take a break and see for yourself why guests,
            return again and again.
          </p>
          <Typography component="p" variant="body1">
            Opening Dates
          </Typography>
          <p>Open 1st March - 31st October.</p>
          <Typography component="p" variant="body1">
            Booking Your Stay
          </Typography>
          <p>
            Bookings can be made online or by contacting 01490 420346. In the
            event we may miss your call please leave a phone message or email us
            on info@glanceirw.com and we shall respond as soon as possible.
            Advanced bookings for weekends, bank holidays, and school holidays
            is highly advised.
          </p>
          <Typography component="p" variant="body1">
            Camping
          </Typography>
          <p>
            You can check-in any time between 1.00pm and 6.00pm on the day of
            arrival; check out by 11am on the day of departure. If you plan to
            arrive after 6.00pm, please telephone to inform us otherwise we may
            re-book the pitch.
          </p>

          <p>
            Please note stays around Bank Holidays are for 3 nights minimum.
          </p>
          <Typography component="p" variant="body1">
            Please note
          </Typography>
          <p>
            You must be 21 years or over to make a booking. Dogs are welcome,
            but please remember to keep them on a lead and clean up after them.
          </p>
          <Typography component="p" variant="body1">
            Information
          </Typography>
          <p>
            If you require any further information or have any questions, then
            please do not hesitate to contact us: By telephone: 01490420346 -
            Email: info@glanceirw.com
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default Info;
