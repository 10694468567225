import React from "react";
import Spinkit from "react-spinkit";
export interface SpinnerProps {
  spinning: boolean;
}

const Spinner = (props: SpinnerProps) => {
  return (
    <div>
      {props.spinning && <Spinkit name="circle" />}
      {!props.spinning && (
        <Spinkit name="circle" style={{ visibility: "hidden" }} />
      )}
    </div>
  );
};
export default Spinner;
