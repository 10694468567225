import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import {initializeApp} from "firebase/app";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore/lite";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {getPerformance} from "firebase/performance";
import {getAnalytics} from  "firebase/analytics";
import Main from "./Main"
// Get the Firebase config from the auto generated file
if (!process.env.REACT_APP_FIREBASE_CONFIG) {
  throw new Error("firebase environment variable not set");
}
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}
// Instantiate a Firebase app.
if (process.env.REACT_APP_BUILD_MODE === "development") {
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_CHECK_DEBUG_TOKEN;
}
const app = initializeApp(firebaseConfig);
getPerformance();
// Initialize Analytics
getAnalytics();
const recaptcha = process.env.REACT_APP_GRECAPTCHA;
if (!recaptcha) {
  throw new Error("recaptcha environment variable not set");
}
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(recaptcha),
  
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const db = getFirestore();
// @ts-ignore
if (window.Cypress) {
  // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
}
if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", 8089);
}
const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </div>
  );
};

export default App;
