import LoadingButton from "@mui/lab/LoadingButton";
import Spinner from "./Spinner";
import Info from "./Info";
import React, { useState, useEffect } from "react";
import { DayPickerInput } from "@bookingflow/components/dist/DayPickerInput";
import { HttpsCallable, Functions } from "firebase/functions";
import { getDownloadURL, ref, getStorage } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  Accommodationtypes,
  IAccommodationtype,
  IAvailabilityFlexResult,
} from "@bookingflow/types";
import dayjs, { Dayjs } from 'dayjs'
import { useSearchParams } from "react-router-dom";

import {
  InputLabel,
  TextField,
  Container,
  Grid,
  useTheme,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  NativeSelect,
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';

interface SearchProps {
  params: any;
  siteId: string;
  adults: number;
  children: number;
  infants: number;
  accommodations: Accommodationtypes;
  accommodation: string;
  from: Date;
  flex: number;
  to: Date;
  spinner: boolean;
  setSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  setAccommodations: React.Dispatch<React.SetStateAction<Accommodationtypes>>;
  setAvailabilityResults: React.Dispatch<
    React.SetStateAction<IAvailabilityFlexResult>
  >;
  setCardImages: React.Dispatch<React.SetStateAction<string[]>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
  infoLogger: HttpsCallable;
  errorLogger: HttpsCallable;
  setSnackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  func: Functions;
  checkAvailability: () => void;
  accommodationSpinner: boolean;
}
const Search = (props: SearchProps) => {
  const [bannerURL, setBannerURL] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchStyle = { backgroundImage: "url(" + bannerURL + ")" };
  const createSelectItems = (accommodations: Accommodationtypes) => {
    let items: JSX.Element[];
    items = [
      <option key="placeholder" value="" disabled={true}>
        Select an accommodation type
      </option>,
    ];
    accommodations.forEach((d: IAccommodationtype, i: number) => {
      items.push(
        <option key={i} value={d.id}>
          {d.accommodation}
        </option>
      );
    });
    return items;
  };

  const cancelRequest = () => {
    props.setSpinner(false);
    props.setError("");
    props.setSnackOpen(false);
  };

  function handleAccommodationChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    cancelRequest();
    const accommodation = event.target.value;
    setSearchParams({ ...props.params, accn: accommodation });
  }

  function handleFromChange(from: Dayjs | null) {
    cancelRequest();
    // Change the from date and focus the "to" input field
    //Date must be later than start date
    // check input is a valid date
    if (!from || !from.isValid) {
      return;
    }
    // if to is less than 1 day later than from
    console.log(from)
    if (dayjs(props.to).diff(from, 'day') < 1) {
      //set it to 1 day after from
      const to = from.add(1, 'day')
      setSearchParams({
        ...props.params,
        arr: from.valueOf().toString(),
        dep: to.valueOf().toString(),
      });
    } else {
      setSearchParams({ ...props.params, arr: from.valueOf().toString() });
    }
  }

  function handleToChange(to: Dayjs | null) {
    cancelRequest();
    // check input is a valid date
    if (!to || !to.isValid) {
      return;
    }

    //props.setTo(to);
    setSearchParams({ ...props.params, dep: to.valueOf().toString() });
  }

  function handleFlexChange(flex: number) {
    return function (event: React.MouseEvent<HTMLElement, MouseEvent>) {
      cancelRequest();
      setSearchParams({ ...props.params, flex: flex.toString() });
    };
  }

  function handleAdultsChange(event: React.ChangeEvent<HTMLInputElement>) {
    cancelRequest();
    const adults = Number(event.target.value).toString();
    setSearchParams({ ...props.params, ad: adults });
  }
  function handleChildrenChange(event: React.ChangeEvent<HTMLInputElement>) {
    cancelRequest();
    const children = Number(event.target.value).toString();
    setSearchParams({ ...props.params, ch: children });
  }

  function handleInfantsChange(event: React.ChangeEvent<HTMLInputElement>) {
    cancelRequest();
    const infants = Number(event.target.value).toString();
    setSearchParams({ ...props.params, inf: infants });
  }

  async function checkAvailabilityForm(event: React.FormEvent) {
    event.preventDefault();
    const analytics = getAnalytics();
    logEvent(analytics, "search");
    await props.checkAvailability();
  }

  const { siteId, infoLogger } = props;
  useEffect(() => {
    if (siteId !== null) {
      
      //Fetch the accommodations relevant to this client
      const storage = getStorage();
      const storageRef = ref(storage);
      const bannerRef = ref(storageRef, siteId + "/images/banner.jpg");
      getDownloadURL(bannerRef)
        .then((downloadURL) => {
          setBannerURL(downloadURL);
        })
        .catch(() => {
          infoLogger({ log: "banner does not exist for siteID: " + siteId });
        });
    }
    // eslint-disable-next-line
  }, []);
  // set accommodation search param if not set once data has been fetched
  const { accommodations } = props;
  useEffect(() => {
    const paramsObject = Object.fromEntries(searchParams.entries());
    if (accommodations.length > 0) {
      const accommodationids = props.accommodations.map(
        (accommodation) => accommodation.id
      );
      const accnParam = searchParams.get("accn");
      if (!accnParam || !accommodationids.includes(accnParam)) {
        setSearchParams({ ...paramsObject, accn: accommodations[0].id });
      }
    }
    // eslint-disable-next-line
  }, [accommodations]);
  useEffect(() => {
    const stringAccommodation = searchParams.get("accn");
    // accommodation is triggered by data fetching so wait until this happens before updating the rest
    if (!stringAccommodation) {
      return;
    }
    const paramsObject = Object.fromEntries(searchParams.entries());

    const stringTo = searchParams.get("dep");
    const stringFrom = searchParams.get("arr");
    const stringAdults = searchParams.get("ad");
    const stringChildren = searchParams.get("ch");
    const stringInfants = searchParams.get("inf");

    if (
      !stringTo &&
      !stringFrom &&
      !stringAdults &&
      !stringChildren &&
      !stringInfants
    ) {
      setSearchParams({
        ...paramsObject,
        arr: props.from.getTime().toString(),
        dep: props.to.getTime().toString(),
        ad: props.adults.toString(),
        ch: props.children.toString(),
        inf: props.infants.toString(),
      });
      return;
    }
    // eslint-disable-next-line
  }, [searchParams]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const buttonGroupOrientation = isSmall ? "vertical" : "horizontal";
  return (
    <section id="banner" style={searchStyle}>
      <Container className="results container">
        <Card className="search-card">
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h4" component="div" gutterBottom>
                Book Online
              </Typography>
            </Grid>
            <form action="" onSubmit={checkAvailabilityForm}>
              <Grid
                container
                spacing={3}
                rowSpacing={5}
                xs={12}
                className="form"
              >
                <Grid item xs={12}>
                  <InputLabel htmlFor="accommodationtype">
                    Accommodation Type
                    <Spinner spinning={props.accommodationSpinner} />
                  </InputLabel>
                  <NativeSelect
                    value={props.accommodation}
                    id="accommodationtype"
                    name="accommodationtype"
                    onChange={handleAccommodationChange}
                  >
                    {createSelectItems(props.accommodations)}
                  </NativeSelect>
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  alignItems="center"
                  id="date-selectors"
                >
                  <Grid item>
                    <DayPickerInput
                      label="Check In"
                      value={props.from}
                      placeholder="From"
                      onDayChange={handleFromChange}
                    />
                  </Grid>
                  <Grid item>
                    <DayPickerInput
                      label="Check Out"
                      value={props.to}
                      placeholder="To"
                      onDayChange={handleToChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      value={props.flex}
                      orientation={buttonGroupOrientation}
                      aria-label="outlined primary button group"
                    >
                      <ToggleButton value={0} onClick={handleFlexChange(0)}>
                        Exact
                      </ToggleButton>
                      <ToggleButton value={1} onClick={handleFlexChange(1)}>
                        <img
                          width={10}
                          src="./static/images/plusminus.svg"
                          alt="plus or minus"
                        />
                        1 day
                      </ToggleButton>
                      <ToggleButton value={2} onClick={handleFlexChange(2)}>
                        <img
                          width={10}
                          src="./static/images/plusminus.svg"
                          alt="plus or minus"
                        />
                        2 days
                      </ToggleButton>
                      <ToggleButton value={3} onClick={handleFlexChange(3)}>
                        <img
                          width={10}
                          src="./static/images/plusminus.svg"
                          alt="plus or minus"
                        />
                        3 days
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  id="individuals"
                >
                  <Grid item xs={12} md="auto">
                    <TextField
                      name="adults"
                      onChange={handleAdultsChange}
                      required={true}
                      type="number"
                      label="Adults (over 12)"
                      inputProps={{
                        min: "0",
                        step: "1",
                      }}
                      value={props.adults}
                    />
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <TextField
                      name="children"
                      onChange={handleChildrenChange}
                      required={true}
                      type="number"
                      label="Children (2-12)"
                      inputProps={{
                        min: "0",
                        step: "1",
                      }}
                      value={props.children}
                    />
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <TextField
                      name="infants"
                      onChange={handleInfantsChange}
                      required={true}
                      inputProps={{
                        min: "0",
                        step: "1",
                      }}
                      type="number"
                      label="Infants (under 2)"
                      value={props.infants}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    disabled={props.accommodations.length === 0}
                    loading={props.spinner}
                  >
                    Check Availability
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        {props.siteId === "RAdjLcHEI68xqbPSnJvE" && <Info />}
      </Container>
    </section>
  );
};
export default Search;
