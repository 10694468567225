import Search from "./Search";
import {Snackbar} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Book.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getFirestore} from "firebase/firestore/lite"
import {connectFunctionsEmulator, getFunctions, httpsCallable} from "firebase/functions";
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import type { PaymentIntent } from "@stripe/stripe-js";
import { Accommodationtypes, IAvailabilityFlexResult } from "@bookingflow/types";
import { config } from "@bookingflow/config";
import Branding from "./Branding";
import { convertToUTCDate, getAccommodationsNew } from "@bookingflow/utils";
import { getApp } from "firebase/app";
import Results from "./Results";
const Extras  = React.lazy(() => import("./Extras"));
const PaymentSuccess = React.lazy(() => import("./PaymentSuccess"));
const PaymentFailure = React.lazy(() => import("./PaymentFailure"));
const Pay = React.lazy(() => import("./Pay"));

const Main = () => {
  const timeoutMinutes = config.timeoutMinutes;
  const timeoutMillis = 1000 * 60 * timeoutMinutes; //20 Minutes
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const app = getApp()
  const func = getFunctions(app, 'europe-west2');
  if (window.location.hostname === "localhost") {
    connectFunctionsEmulator(func, "localhost", 5001);
  }
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const infoLogger = httpsCallable(func, "log");
  const errorLogger = httpsCallable(func, "error");
  const navigate = useNavigate();
  const location = useLocation();
  const [accommodations, setAccommodations] = useState<Accommodationtypes>([]);
  const [from, setFrom] = useState(new Date(new Date().setHours(0,0,0,0)));
  const [to, setTo] = useState(
    new Date(from.getTime() + 24 * 60 * 60 * 1000)
  );
  const [flex, setFlex] = useState(0)
  const [accommodation, setAccommodation] = useState<string>("");
  const [spinner, setSpinner] = useState(false);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const db = getFirestore();
  const [accommodationSpinner, setAccommodationSpinner] = useState(true);
  const [siteId, setSiteId] = useState<string | null>("");
  const [paymentIntent, setPaymentIntent] = useState<
    PaymentIntent | string | null
  >(null);
  const [availabilityResults, setAvailabilityResults] =
    useState<IAvailabilityFlexResult>({
      id: "",
      availability: { isAvailable: false, message: "" },
    });
  const [error, setError] = useState("");
  const [cardImages, setCardImages] = useState<string[]>([]);

  /** startTimer sets up a prompt to confirm they want to leave the page
   * and it sets a timeout giving config.timeoutMinutes before it ends the session
   * This is triggered when the user clicks the book button in ResultsCard
   * It is up here so that is isn't lost when the component is unmounted
   */
  function startTimer() {
    setOpen(true);
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    });
    setTimeout(async () => {
      navigate("/book?site_id=" + siteId);
    }, timeoutMillis);
  };
  /** fetch card images */
  // TODO: move to ResultCard
  const fetchImages = async () => {
    const storage = getStorage()
    const storageRef = ref(storage);
    const firstRef = ref(storageRef,
      siteId + "/images/" + accommodation + "/image1.jpg"
    );
    const secondRef = ref(storageRef, 
      siteId + "/images/" + accommodation + "/image2.jpg"
    );
    const thirdRef = ref(storageRef,
      siteId + "/images/" + accommodation + "/image3.jpg"
    );
    // execute in parallel
    const firstDownload = getDownloadURL(firstRef).catch(() => {
      infoLogger({ log: "image1 does not exist for siteID: " + siteId });
      return "";
    });
    const secondDownload = getDownloadURL(secondRef).catch(() => {
      infoLogger({ log: "image2 does not exist for siteID: " + siteId });
      return "";
    });
    const thirdDownload = getDownloadURL(thirdRef).catch(() => {
      infoLogger({ log: "image3 does not exist for siteID: " + siteId });
      return "";
    });
    const [firstURL, secondURL, thirdURL] = await Promise.all([firstDownload, secondDownload, thirdDownload])
    setCardImages([firstURL, secondURL, thirdURL]);
  };
  async function checkAvailability() {
    // This needs to be at this level so it can:
    // be triggered by and show the error message on the search component
    // be triggered by the results component on a page refresh
    if (accommodation !== "") {
      if (spinner === true) {
        //TODO: cancel previous transaction
        return;
      }
      setSpinner(true);
      // can't pass date object over http request
      // first convert local datetime to utc date
      const utcFrom = convertToUTCDate(from);
      const utcTo = convertToUTCDate(to);
      // then convert to millis
      const utcFromTime = utcFrom.getTime();
      const utcToTime = utcTo.getTime();
      const getAvailableIds = httpsCallable(func, "portalGetAvailableIds");
      const res = await getAvailableIds({
        siteId,
        utcFromTime,
        utcToTime,
        accommodation,
        adults,
        children,
        infants,
        flex
      });
      const data: IAvailabilityFlexResult = res.data as IAvailabilityFlexResult;
      if (!data.availability.isAvailable) {
        infoLogger({
          log:
            "no availability results returned: " +
            siteId +
            utcToTime.toString() +
            utcFromTime.toString() +
            accommodation +
            data.availability.message,
        });
        setError(data.availability.message);
        setSnackOpen(true);
        setSpinner(false);

        return;
      } else {
        setSpinner(false);
        setAvailabilityResults(data);
        await fetchImages();
      }
      //const url = `/book/quote?site_id=${siteId}&accn=${accommodation}&arr=${from.getTime()}&dep=${to.getTime()}&ad=${adults}&ch=${children}&inf=${infants}#results&flex=${flex}`;
      const url = `${location.pathname}${location.search}`.replace("?", "/quote?")
      if (`${location.pathname}` !== "/book/quote") {
        navigate(url);
      }
    } else {
      errorLogger({
        error: "cannot check availability as accommodations is empty",
      });
    }
  };
  /** populate siteId from SearchParams */
  useEffect(() => {
    const fetchAccommodations = async (siteId: string) => {
      //fetch accommodations
      const accommodations: Accommodationtypes | undefined =
        await getAccommodationsNew(siteId, errorLogger, db);
      if (!accommodations) {
        return;
      }
      /*
          //TODO: use service workers and background sync to request from firebase
          const swRegistration = await navigator.serviceWorker.ready
          const res = swRegistration.sync.register('fetchAccommodations')
          */
      setAccommodations(accommodations);
      setAccommodationSpinner(false);
    };
    //TODO: create spinner until this returns
    const siteId = searchParams.get("site_id");
    setSiteId(siteId);
    if (siteId === null) {
      infoLogger({ log: "siteId does not exist" });
    } else {
      fetchAccommodations(siteId);
    }
    // eslint-disable-next-line
  }, []);

  /** if search params are in URL then use them on page refresh */
  useEffect(() => {
    const paramsObject = Object.fromEntries(searchParams.entries());
    setParams(paramsObject);

    const stringAccommodation = searchParams.get("accn");
    // accommodation is triggered by data fetching so wait until this happens before updating the rest
    if (!stringAccommodation) {
      return;
    }
    setAccommodation(stringAccommodation);
    const stringTo = searchParams.get("dep");
    const stringFrom = searchParams.get("arr");
    const stringAdults = searchParams.get("ad");
    const stringChildren = searchParams.get("ch");
    const stringInfants = searchParams.get("inf");
    const stringFlex = searchParams.get("flex");

    // Because javascript evaluates 0 as falsey
    const validValue = (value: string | null) => {
      const invalidValues = [null, ""];
      return !invalidValues.includes(value);
    };
    if (stringTo) {
      const toParam = new Date(Number(stringTo));
      setTo(new Date(toParam));
    }
    if (stringFrom) {
      const fromParam = new Date(Number(stringFrom));
      setFrom(new Date(fromParam));
    }
    if (stringFlex) {
      const flexParam = Number(stringFlex);
      setFlex(flexParam);
    }
    if (validValue(stringAdults)) {
      const adultsParam = Number(stringAdults);
      setAdults(Number(adultsParam));
    }
    if (validValue(stringChildren)) {
      const childrenParam = Number(stringChildren);
      setChildren(Number(childrenParam));
    }
    if (validValue(stringInfants)) {
      const infantsParam = Number(stringInfants);
      setInfants(Number(infantsParam));
    }

    // eslint-disable-next-line
  }, [searchParams]);

  function handleClose (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  return (
    <div className="Main">
      {siteId && (
        <React.Fragment>
          <Routes>
            <Route
              path="/"
              element={
                <Branding
                  open={open}
                  setOpen={setOpen}
                  siteId={siteId}
                  timeoutMinutes={timeoutMinutes}
                />
              }
            >
              <Route
                path="book"
                element={
                  <Search
                    params={params}
                    siteId={siteId}
                    adults={adults}
                    children={children}
                    infants={infants}
                    accommodations={accommodations}
                    accommodation={accommodation}
                    from={from}
                    to={to}
                    flex={flex}
                    spinner={spinner}
                    setSpinner={setSpinner}
                    setAccommodations={setAccommodations}
                    setAvailabilityResults={setAvailabilityResults}
                    checkAvailability={checkAvailability}
                    setCardImages={setCardImages}
                    setError={setError}
                    setSnackOpen={setSnackOpen}
                    infoLogger={infoLogger}
                    errorLogger={errorLogger}
                    accommodationSpinner={accommodationSpinner}
                    func={func}
                  />
                }
              />
              <Route
                path="book/quote"
                element={
                  <Results
                    error={error}
                    images={cardImages}
                    accommodations={accommodations}
                    accommodation={accommodation}
                    availabilityResults={availabilityResults}
                    arrive={from}
                    depart={to}
                    adults={adults}
                    children={children}
                    infants={infants}
                    spinner={spinner}
                    siteId={siteId}
                    flex={flex}
                    startTimer={startTimer}
                    func={func}
                    setPaymentIntent={setPaymentIntent}
                    setSpinner={setSpinner}
                    checkAvailability={checkAvailability}
                  />
                }
              />
              <Route
                path="book/pay/"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Pay
                      searchParams={searchParams}
                      paymentIntent={paymentIntent}
                      func={func}
                    />
                  </React.Suspense>
                }
              />
            </Route>
            <Route path="/book/extras" element={<React.Suspense fallback={<>...</>}><Extras /></React.Suspense>} />
            <Route path="/payment/success" element={<React.Suspense fallback={<>...</>}><PaymentSuccess /></React.Suspense>} />
            <Route path="/payment/failure" element={<React.Suspense fallback={<>...</>}><PaymentFailure /></React.Suspense>} />
          </Routes>
          <Snackbar
            id="error-snackbar"
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            message={error}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Main;
